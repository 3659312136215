import { FunctionComponent, useEffect } from 'react';
import Context from '@ui/components/context';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { withTrans } from '@shares/locales/hoc';
import Layout from '@shares/layout';
import SEO from '@shares/seo';
import { WEB_LEGACY_LINK } from '@config';
import { ContentQueryResult, HelpCenterPageProps } from './interface';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { trackHelpCenter } from './tracking';
import { PageSection } from '@utils';

import './style.scss';

const helpTitle = {
  th: 'ให้เราช่วยอะไรคุณได้บ้าง?',
  en: 'What can we help you with?'
};

const HelpCenterPage = (props: PageWithTranslationProps) => (
  <StaticQuery
    query={graphql`
      query {
        content: allMarkdownRemark(filter: { fields: { collection: { eq: "content" } } }) {
          edges {
            node {
              id
              frontmatter {
                path
                position
                name_th
                name_en
                icon {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                short_description_th
                short_description_en
              }
            }
          }
        }
      }
    `}
    render={(data: ContentQueryResult) => (
      <Context>
        <HelpCenter data={data} {...props} />
      </Context>
    )}
  />
);

const HelpCenter: FunctionComponent<HelpCenterPageProps> = (props) => {
  const { prefix } = props.pageContext;
  const { data } = props;
  const contents = data.content.edges;
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(prefix);
    console.log(prefix);
  }, [prefix]);
  return (
    <Layout section={PageSection.Help} prefix={prefix} isTransparent={false}>
      <SEO
        title="Help Center"
        prefix={prefix}
        path="help-center"
        canonical={`https://${WEB_LEGACY_LINK}/${prefix}/help-center/`}
        description="ให้เราช่วยอะไรคุณได้บ้าง ติดต่อเราได้อย่างไร ..."
        keywords="วิธีการเช่า, บริการของเรา, การรับรถ, ติดต่อ, Drivehub"
        disableAppBanner
      />
      <div className="help-center">
        <Container>
          <h1 className="header text-center">{helpTitle[prefix]}</h1>
          <Row className="justify-content-center">
            {contents
              .filter(({ node }) => node.frontmatter.path !== 'list-your-car')
              .sort((a, b) => {
                return parseInt(a.node.frontmatter.position) - parseInt(b.node.frontmatter.position);
              })
              .map(({ node }, i) => {
                const imageData = getImage(node.frontmatter.icon);
                return (
                  <Col md={4} className="my-2 px-3 px-md-2" key={i}>
                    <Card
                      as="a"
                      className="card--help shadow"
                      id={`help-center-${node.frontmatter.path}`}
                      href={`/${prefix}/${node.frontmatter.path}`}
                      onClick={() => trackHelpCenter(node.frontmatter[`name_th`])}
                    >
                      {imageData && (
                        <GatsbyImage
                          className="content-icon"
                          image={imageData}
                          alt={node.frontmatter[`name_${prefix}`]}
                        />
                      )}
                      <Card.Body>
                        <Card.Title className="content-title" as="h5">
                          {node.frontmatter[`name_${prefix}`]}
                        </Card.Title>
                        <Card.Text className="content-sub-title">
                          {node.frontmatter[`short_description_${prefix}`]}
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer className="bg-white">
                        <i className="icon-right" />
                      </Card.Footer>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default withTrans()(HelpCenterPage);
