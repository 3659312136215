import { sendToDataLayer } from '@utils';
const CONTENT_EVENT_CATEGORY = 'content_section';
export const trackContentItem = (contentName: string) => {
  sendToDataLayer(undefined, {
    event_category: CONTENT_EVENT_CATEGORY,
    event_action: 'click_menu',
    event_label: contentName
  });
};
const HELP_CENTER_EVENT_CATEGORY = 'helpcenter_section';
export const trackHelpCenter = (menuName: string) => {
  sendToDataLayer(undefined, {
    event_category: HELP_CENTER_EVENT_CATEGORY,
    event_label: menuName
  });
};
